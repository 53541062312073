<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <Navbar />
        <v-row v-if="success" justify="center" class="ma-0 mt-5">
            {{ $t("deleteAccount.message") }}
        </v-row>
        <v-row v-else justify="center" class="ma-0 mt-5">
            <v-alert v-if="error" type="error" outlined>{{ error }}</v-alert>
        </v-row>
    </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar'
import PopupDialog from '@/components/partials/PopupDialog'
import axios from 'axios'
    export default {
        name: "PasswordRecoveryCode",
        components: {Navbar, PopupDialog},
        data() {
            return {
                dialog: false,
                success: false,
                error: ''
            }
        },
        created () {
            axios.post('/delete_account_code', {code: this.$route.params.code})
            .then(() => {
                this.success = true
                this.error = ''
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    if (error.response.data.message == "Checksum failed") {
                        this.error = this.$t("deleteAccount.errors.checksumFailed")
                    } else if (error.response.data.message == "Invalid code") {
                        this.error = this.$t("deleteAccount.errors.invalidCode")
                    } else {
                        this.dialog = true
                    }
                } else {
                    this.dialog = true
                }
            })
        }
    }
</script>

<style lang="scss" scoped>

</style>